define('fixtable-ember/components/fixtable-row', ['exports', 'ember', 'fixtable-ember/templates/components/fixtable-row'], function (exports, _ember, _fixtableEmberTemplatesComponentsFixtableRow) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _fixtableEmberTemplatesComponentsFixtableRow['default'],
    tagName: 'tr',
    classNames: ['fixtable-row'],
    classNameBindings: ['active', 'expanded', 'hover', 'primary:fixtable-row-primary:fixtable-row-secondary'],
    click: function click() {
      this.get('onClick')();
    },
    mouseEnter: function mouseEnter() {
      this.set('hover', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hover', false);
    }
  });
});