define('ember-context-menu/components/context-menu-item', ['exports', 'ember-context-menu/templates/components/context-menu-item', 'ember-invoke-action'], function (exports, _contextMenuItem, _emberInvokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _contextMenuItem.default,

    tagName: 'li',

    classNames: ['context-menu__item'],
    classNameBindings: ['isDisabled:context-menu__item--disabled', '_isParent:context-menu__item--parent'],

    _amount: Ember.computed('_isParent', 'amount', function () {
      var amount = Ember.get(this, 'amount');

      return !Ember.get(this, '_isParent') && amount > 1 && amount;
    }),

    _isParent: Ember.computed.bool('item.subActions.length'),

    isDisabled: Ember.computed('item.{disabled,action}', 'itemIsDisabled', function () {
      var item = Ember.get(this, 'item');
      return (0, _emberInvokeAction.default)(this, 'itemIsDisabled', item);
    }),

    click: function click() {
      if (!Ember.get(this, 'isDisabled') && !Ember.get(this, '_isParent')) {
        (0, _emberInvokeAction.default)(this, 'clickAction', Ember.get(this, 'item'));
      }
    }
  });
});