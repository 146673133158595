define('ember-models-table/utils/macros', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shownColumns = shownColumns;


  /**
   * @param {string} colspanKey
   * @returns {Ember.computed}
   */
  function shownColumns(colspanKey) {
    return Ember.computed('processedColumns.@each.{isVisible,' + colspanKey + '}', function () {
      var skipCount = 0;
      return Ember.get(this, 'processedColumns').filter(function (c, index, columns) {
        var colspan = Ember.get(c, colspanKey);
        var isVisible = Ember.get(c, 'isVisible');
        var nextHiddenCells = columns.slice(index + 1, index + colspan).filter(function (c) {
          return Ember.get(c, 'isHidden');
        });
        if (Ember.get(nextHiddenCells, 'length') === colspan - 1 && !isVisible && colspan !== 1) {
          return false;
        }
        if (skipCount) {
          skipCount--;
          return false;
        }
        if (colspan === 1) {
          return isVisible;
        }
        if (colspan > 1) {
          skipCount = colspan - 1;
        }
        return true;
      });
    }).readOnly();
  }
});