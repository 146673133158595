define('ember-document-title/services/document-title-list', ['exports', 'ember'], function (exports, _ember) {

  var _get = _ember['default'].get;
  var set = _ember['default'].set;
  var copy = _ember['default'].copy;
  exports['default'] = _ember['default'].Service.extend({

    init: function init() {
      this._super();
      set(this, 'tokens', _ember['default'].A());
      set(this, 'length', 0);
    },

    defaultSeparator: ' | ',

    push: function push(token) {
      var defaultSeparator = _get(this, "defaultSeparator");

      var tokenForId = this.tokens.findBy('id', token.id);
      if (tokenForId) {
        var index = this.tokens.indexOf(tokenForId);
        var _tokens = copy(this.tokens);
        var _previous = tokenForId.previous;
        token.previous = _previous;
        token.next = tokenForId.next;
        if (_previous) {
          if (token.separator == null) {
            token.separator = _previous.separator;
          }

          if (token.prepend == null) {
            token.prepend = _previous.prepend;
          }
        }

        if (token.separator == null) {
          token.separator = defaultSeparator;
        }

        _tokens.splice(index, 1, token);
        set(this, 'tokens', _ember['default'].A(_tokens));
        return;
      }

      var previous = this.tokens.slice(-1)[0];
      if (previous) {
        token.previous = previous;
        previous.next = token;

        if (token.separator == null) {
          token.separator = previous.separator;
        }

        if (token.prepend == null) {
          token.prepend = previous.prepend;
        }
      }

      if (token.separator == null) {
        token.separator = defaultSeparator;
      }

      var tokens = copy(this.tokens);
      tokens.push(token);
      set(this, 'tokens', _ember['default'].A(tokens));
      set(this, 'length', _get(this, 'length') + 1);
    },

    remove: function remove(id) {
      var token = this.tokens.findBy('id', id);
      var next = token.next;
      var previous = token.previous;
      if (next) {
        next.previous = previous;
      }

      if (previous) {
        previous.next = next;
      }

      token.previous = token.next = null;

      var tokens = _ember['default'].A(copy(this.tokens));
      tokens.removeObject(token);
      set(this, 'tokens', _ember['default'].A(tokens));
      set(this, 'length', _get(this, 'length') - 1);
    },

    visibleTokens: _ember['default'].computed('tokens', {
      get: function get() {
        var tokens = _get(this, 'tokens');
        var i = tokens.length;
        var visible = [];
        while (i--) {
          var token = tokens[i];
          if (token.replace) {
            visible.unshift(token);
            break;
          } else {
            visible.unshift(token);
          }
        }
        return visible;
      }
    }),

    sortedTokens: _ember['default'].computed('visibleTokens', {
      get: function get() {
        var visible = _get(this, 'visibleTokens');
        var appending = true;
        var group = [];
        var groups = _ember['default'].A([group]);
        visible.forEach(function (token) {
          if (token.prepend) {
            if (appending) {
              appending = false;
              group = [];
              groups.push(group);
            }
            var lastToken = group[0];
            if (lastToken) {
              token = copy(token);
              token.separator = lastToken.separator;
            }
            group.unshift(token);
          } else {
            if (!appending) {
              appending = true;
              group = [];
              groups.push(group);
            }
            group.push(token);
          }
        });

        return groups.reduce(function (E, group) {
          return E.concat(group);
        }, []);
      }
    }),

    toString: function toString() {
      var tokens = _get(this, 'sortedTokens');
      var title = [];
      for (var i = 0, len = tokens.length; i < len; i++) {
        var token = tokens[i];
        title.push(token.title);
        if (i + 1 < len) {
          title.push(token.separator);
        }
      }
      return title.join('');
    }
  });
});