define('fixtable-ember/helpers/get-select-options', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.getSelectOptions = getSelectOptions;

  function getSelectOptions(_ref /*, hash*/) {
    var _ref2 = _slicedToArray(_ref, 3);

    var column = _ref2[0];
    var dataRows = _ref2[1];
    var serverPaging = _ref2[2];

    if (!column || !column.filter || column.filter.type !== 'select') {
      throw 'Cannot call getSelectOptions on a column without a select-type filter';
    }

    if (column.filter.automaticOptions) {
      var _ret = (function () {
        if (serverPaging) {
          throw 'Cannot use automaticOptions for select-type filter on column ' + column.key + ' while serverPaging is turned on';
        }

        var optionSet = {};
        dataRows.forEach(function (row) {
          var val = row.get ? row.get(column.key) : row[column.key];
          if (typeof val === 'undefined' || val === null || val === '') {
            return;
          }

          var lower = (val + '').toLowerCase();
          if (typeof optionSet[lower] !== 'undefined') {
            return;
          } // we already have this option
          optionSet[lower] = val;
        });

        return {
          v: Object.keys(optionSet).map(function (opt) {
            return { value: optionSet[opt] };
          })
        };
      })();

      if (typeof _ret === 'object') return _ret.v;
    }

    if (!column.filter.selectOptions) {
      throw 'Select options must be specified for filter in column ' + column.key;
    }
    return column.filter.selectOptions;
  }

  exports['default'] = _ember['default'].Helper.helper(getSelectOptions);
});