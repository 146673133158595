define('ember-css-modules/helpers/local-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.localClass = localClass;
  function localClass(params, hash) {
    (true && !('from' in hash) && Ember.assert('No source specified to local-class lookup', 'from' in hash));

    if (!hash.from) {
      return '';
    }

    var styles = resolveSource(hash.from);
    var classes = (params[0] || '').split(/\s+/);

    return classes.map(function (style) {
      return styles[style];
    }).filter(Boolean).join(' ');
  }

  exports.default = Ember.Helper.helper(localClass);


  function resolveSource(source) {
    if (typeof source === 'string') {
      return window.require(source).default;
    } else {
      return source;
    }
  }
});