define('ember-context-menu/mixins/context-menu', ['exports', 'ember-invoke-action'], function (exports, _emberInvokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    contextMenuService: Ember.inject.service('context-menu'),

    contextMenu: function contextMenu(e) {
      (0, _emberInvokeAction.default)(this, '_contextMenu', e);

      var contextMenu = Ember.get(this, 'contextMenuService');
      var items = Ember.get(this, 'contextItems');
      var selection = Ember.get(this, 'contextSelection');
      var details = Ember.get(this, 'contextDetails');

      if (items && Ember.get(items, 'length')) {
        e.preventDefault();
        contextMenu.activate(e, items, selection, details);
      }
    }
  });
});