define('ember-leaflet-layer-control/components/layer-control', ['exports', 'ember-leaflet/components/base-layer'], function (exports, _baseLayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      isEmpty = Ember.isEmpty,
      isBlank = Ember.isBlank;
  exports.default = _baseLayer.default.extend({
    didInsertParent: function didInsertParent() {
      this._layer = this.createLayer();
      this._addObservers();
      this._addEventListeners();
      if (get(this, 'parentComponent')) {
        this._layer.addTo(get(this, 'parentComponent')._layer);
      }
      this.didCreateLayer();
    },
    createLayer: function createLayer() {
      //L is defined globally by Leaflet
      return L.control.layers(); // jshint ignore:line
    },
    didCreateLayer: function didCreateLayer() {
      var _this = this;

      this._super.apply(this, arguments);
      var baseLayers = get(this, 'parentComponent._baseLayers');
      if (!isBlank(baseLayers)) {
        baseLayers.forEach(function (baseLayer) {
          if (baseLayer.default) {
            var parentLayer = get(_this, 'parentComponent._layer');
            baseLayer.layer.addTo(parentLayer);
          }
          _this._layer.addBaseLayer(baseLayer.layer, baseLayer.name);
        });
      }
      var layerGroups = get(this, 'parentComponent._layerGroups');
      if (!isBlank(layerGroups)) {
        layerGroups.forEach(function (layerGroup) {
          var parentLayer = get(_this, 'parentComponent._layer');
          if (layerGroup.default) {
            layerGroup.layer.addTo(parentLayer);
          } else {
            layerGroup.layer.removeFrom(parentLayer);
          }
          _this._layer.addOverlay(layerGroup.layer, layerGroup.name);
        });
      }
      var handler = get(this, 'handler') || null;
      if (!isEmpty(handler)) {
        var map = get(this, 'parentComponent')._layer;
        map.on('overlayadd', this.attrs.handler);
        map.on('overlayremove', this.attrs.handler);
        map.on('baselayerchange', this.attrs.handler);
      }
    }
  });
});