define('ember-leaflet/components/path-layer', ['exports', 'ember-leaflet/components/interactive-layer', 'ember-leaflet/mixins/div-overlayable', 'ember-leaflet/mixins/style'], function (exports, _interactiveLayer, _divOverlayable, _style) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _interactiveLayer.default.extend(_divOverlayable.default, _style.default, {

    leafletOptions: Object.freeze(['stroke', 'color', 'weight', 'opacity', 'fill', 'fillColor', 'fillOpacity', 'fillRule', 'dashArray', 'lineCap', 'lineJoin', 'clickable', 'pointerEvents', 'className']),

    leafletEvents: Object.freeze(['add', 'remove', 'popupopen', 'popupclose'])
  });
});