define('ember-models-table/components/models-table-server-paginated', ['exports', 'ember-models-table/components/models-table', 'ember-models-table/templates/components/models-table'], function (exports, _modelsTable, _modelsTable2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var extend = Ember.$.extend;
  exports.default = _modelsTable.default.extend({

    layout: _modelsTable2.default,

    /**
     * True if data is currently being loaded from the server.
     * Can be used in the template to e.g. display a loading spinner.
     *
     * @type boolean
     * @property isLoading
     * @default false
     * @private
     */
    isLoading: false,

    /**
     * True if last data query promise has been rejected.
     * Can be used in the template to e.g. indicate stale data or to e.g. show error state.
     *
     * @type boolean
     * @property isError
     * @default false
     * @private
     */
    isError: false,

    /**
     * The property on meta to load the pages count from.
     *
     * @type string
     * @property metaPagesCountProperty
     * @default 'pagesCount'
     */
    metaPagesCountProperty: 'pagesCount',
    /**
     * The property on meta to load the total item count from.
     *
     * @type {string}
     * @property metaItemsCountProperty
     * @default 'itemsCount'
     */
    metaItemsCountProperty: 'itemsCount',

    /**
     * The time to wait until new data is actually loaded.
     * This can be tweaked to avoid making too many server requests.
     *
     * @type number
     * @property debounceDataLoadTime
     * @default 500
     */
    debounceDataLoadTime: 500,

    /**
     * The query parameters to use for server side filtering / querying.
     *
     * @type object
     * @property filterQueryParameters
     */
    filterQueryParameters: Ember.computed(function () {
      return {
        globalFilter: 'search',
        sort: 'sort',
        sortDirection: 'sortDirection',
        page: 'page',
        pageSize: 'pageSize'
      };
    }),

    /**
     * @property observedProperties
     * @type string[]
     * @private
     */
    observedProperties: Ember.computed(function () {
      return ['currentPageNumber', 'sortProperties.[]', 'pageSize', 'filterString', 'processedColumns.@each.filterString'];
    }),

    /**
     * This is set during didReceiveAttr and whenever the page/filters change.
     *
     * @override
     * @property filteredContent
     * @default []
     * @private
     * @type object[]
     */
    filteredContent: null,

    /**
     * For server side filtering, visibleContent is same as the filtered content
     *
     * @override
     * @property visibleContent
     * @private
     * @type object[]
     */
    visibleContent: Ember.computed.alias('arrangedContent'),

    /**
     * For server side filtering, arrangedContent is same as the filtered content
     *
     * @override
     * @property arrangedContent
     * @private
     * @type object[]
     */
    arrangedContent: Ember.computed.alias('filteredContent'),

    /**
     * Can't be used within `models-table-server-paginated`. Back-end determines how to filter data
     *
     * @override
     * @property filteringIgnoreCase
     */
    filteringIgnoreCase: Ember.computed({
      set: function set() {
        (true && !(false) && Ember.assert('"filteringIgnoreCase" can\'t be used with "models-table-server-paginated"', false));
      },
      get: function get() {
        return undefined;
      }
    }),

    /**
     * Can't be used within `models-table-server-paginated`. Back-end determines how to filter data
     *
     * @override
     * @property doFilteringByHiddenColumns
     */
    doFilteringByHiddenColumns: Ember.computed({
      set: function set() {
        (true && !(false) && Ember.assert('"doFilteringByHiddenColumns" can\'t be used with "models-table-server-paginated"', false));
      },
      get: function get() {
        return undefined;
      }
    }),

    /**
     * The total content length is get from the meta information.
     * Set metaItemsCountProperty to change from which meta property this is loaded.
     *
     * @override
     * @type number
     * @property arrangedContentLength
     * @private
     */
    arrangedContentLength: Ember.computed('filteredContent.meta', function () {
      var itemsCountProperty = Ember.get(this, 'metaItemsCountProperty');
      var meta = Ember.get(this, 'filteredContent.meta') || {};
      return Ember.get(meta, itemsCountProperty) || 0;
    }),

    /**
     * The pages count is get from the meta information.
     * Set metaPagesCountProperty to change from which meta property this is loaded.
     *
     * @type number
     * @property pagesCount
     * @override
     * @private
     */
    pagesCount: Ember.computed('filteredContent.meta', function () {
      var pagesCountProperty = Ember.get(this, 'metaPagesCountProperty');
      var meta = Ember.get(this, 'filteredContent.meta') || {};
      return Ember.get(meta, pagesCountProperty) || 1;
    }),

    /**
     * The index of the last item that is currently being shown.
     *
     * @type number
     * @property lastIndex
     * @override
     * @private
     */
    lastIndex: Ember.computed('pageSize', 'currentPageNumber', 'arrangedContentLength', function () {
      var pageMax = parseInt(Ember.get(this, 'pageSize'), 10) * Ember.get(this, 'currentPageNumber');
      var itemsCount = Ember.get(this, 'arrangedContentLength');
      return Math.min(pageMax, itemsCount);
    }),

    /**
     * This function actually loads the data from the server.
     * It takes the store, modelName and query from the passed in data-object and adds page, sorting & filtering to it.
     *
     * @returns {Promise}
     * @method _loadData
     * @private
     */
    _loadData: function _loadData() {
      var _this = this;

      var data = Ember.get(this, 'data');
      var currentPageNumber = Ember.get(this, 'currentPageNumber');
      var pageSize = Ember.get(this, 'pageSize');
      var columns = Ember.get(this, 'processedColumns');
      var sortProperties = Ember.get(this, 'sortProperties');
      var filterString = Ember.get(this, 'filterString');

      if (!Ember.get(data, 'query')) {
        (true && Ember.warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data'));

        return;
      }
      var query = extend({}, Ember.get(data, 'query'));
      var store = Ember.get(data, 'store');
      var modelName = Ember.get(data, 'type.modelName');

      // Add pagination information
      query[Ember.get(this, 'filterQueryParameters.page')] = currentPageNumber;
      query[Ember.get(this, 'filterQueryParameters.pageSize')] = pageSize;
      // Add sorting information
      var sort = sortProperties && Ember.get(sortProperties, 'length') ? sortProperties[0] : null;
      if (sort) {
        var _sort$split = sort.split(':'),
            _sort$split2 = _slicedToArray(_sort$split, 2),
            sortBy = _sort$split2[0],
            sortDirection = _sort$split2[1];

        query = this.sortingWrapper(query, sortBy, sortDirection.toUpperCase());
      } else {
        delete query[[Ember.get(this, 'filterQueryParameters.sort')]];
        delete query[[Ember.get(this, 'filterQueryParameters.sortDirection')]];
      }

      // Add global filter
      var globalFilter = Ember.get(this, 'filterQueryParameters.globalFilter');
      if (filterString) {
        query[globalFilter] = filterString;
      } else {
        delete query[globalFilter];
      }

      // Add per-column filter
      if (Ember.get(this, 'useFilteringByColumns')) {
        columns.forEach(function (column) {
          var filter = Ember.get(column, 'filterString');
          var filterTitle = _this.getCustomFilterTitle(column);
          _this._setQueryFilter(query, column, filterTitle, filter);
        });
      }

      Ember.setProperties(this, { isLoading: true, isError: false });
      return this.doQuery(store, modelName, query).then(function () {
        return Ember.setProperties(_this, { isLoading: false, isError: false });
      }).catch(function () {
        return Ember.setProperties(_this, { isLoading: false, isError: true });
      });
    },

    /**
     * Do query-request to load new data
     *
     * You may override this method to add some extra behavior or even additional requests
     *
     * @method doQuery
     * @param {object} store
     * @param {string} modelName
     * @param {object} query
     * @returns {Promise}
     */
    doQuery: function doQuery(store, modelName, query) {
      var _this2 = this;

      return store.query(modelName, query).then(function (newData) {
        return Ember.set(_this2, 'filteredContent', newData);
      });
    },


    /**
     * Actually set the filter on a query.
     * This can be overwritten for special case handling.
     * Note that this will mutate the given query object!
     *
     * @param {object} query the query to mutate
     * @param {object} column the column that is filtering
     * @param {string} filterTitle the query param name for filtering
     * @param {mixed} filter the actual filter value
     * @returns {undefined}
     * @method _setQueryFilter
     * @private
     */
    _setQueryFilter: function _setQueryFilter(query, column, filterTitle, filter) {
      if (!Ember.isBlank(filter)) {
        query[filterTitle] = filter;
      } else {
        delete query[filterTitle];
      }
    },


    /**
     * Wrapper for sorting query
     *
     * @param {object} query parameters
     * @param {string} sortBy
     * @param {string} sortDirection
     * @returns {object} query parameters
     * @method sortingWrapper
     */
    sortingWrapper: function sortingWrapper(query, sortBy, sortDirection) {
      query[Ember.get(this, 'filterQueryParameters.sort')] = sortBy;
      query[Ember.get(this, 'filterQueryParameters.sortDirection')] = sortDirection;

      return query;
    },


    /**
     * Customize filter title
     *
     * @method getCustomFilterTitle
     * @param {object} column
     * @returns {string} title
     */
    getCustomFilterTitle: function getCustomFilterTitle(column) {
      return Ember.get(column, 'filteredBy') || Ember.get(column, 'propertyName');
    },


    actions: {

      /**
       * @override
       * @method actions.sort
       * @param {ModelsTableColumn} column
       * @returns {undefined}
       */
      sort: function sort(column) {
        var sortMap = {
          none: 'asc',
          asc: 'desc',
          desc: 'none'
        };
        var sortedBy = Ember.get(column, 'sortedBy') || Ember.get(column, 'propertyName');
        if (Ember.isNone(sortedBy)) {
          return;
        }
        var currentSorting = Ember.get(column, 'sorting');
        var newSorting = sortMap[currentSorting.toLowerCase()];
        var sortingArgs = [column, sortedBy, newSorting];
        this._singleColumnSorting.apply(this, sortingArgs);
        this.userInteractionObserver();
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'filteredContent', Ember.get(this, 'data'));
    },
    _addPropertyObserver: function _addPropertyObserver() {
      Ember.run.debounce(this, this._loadData, Ember.get(this, 'debounceDataLoadTime'));
    },
    willInsertElement: function willInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);
      var observedProperties = Ember.get(this, 'observedProperties');
      observedProperties.forEach(function (propertyName) {
        return _this3.addObserver(propertyName, _this3._addPropertyObserver);
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this4 = this;

      this._super.apply(this, arguments);
      var observedProperties = Ember.get(this, 'observedProperties');
      observedProperties.forEach(function (propertyName) {
        return _this4.removeObserver(propertyName);
      });
    }
  });
});