define('fixtable-ember/components/fixtable-column-header', ['exports', 'ember', 'fixtable-ember/templates/components/fixtable-column-header'], function (exports, _ember, _fixtableEmberTemplatesComponentsFixtableColumnHeader) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _fixtableEmberTemplatesComponentsFixtableColumnHeader['default'],
    tagName: 'th',

    column: null,
    sortBy: null,
    sortAscending: null,

    actions: {
      sortColumn: function sortColumn(columnKey) {
        this.sendAction('sortColumn', columnKey);
      }
    }
  });
});