define('ember-leaflet-layer-control/components/layer-group', ['exports', 'ember-leaflet/components/base-layer', 'ember-composability-tools'], function (exports, _baseLayer, _emberComposabilityTools) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      isEmpty = Ember.isEmpty,
      set = Ember.set,
      isNone = Ember.isNone;
  exports.default = _baseLayer.default.extend(_emberComposabilityTools.ParentMixin, {
    baselayer: false,
    blockLayer: false,
    default: false,
    createLayer: function createLayer() {
      return this.L.layerGroup();
    },
    registerNameOnParent: function registerNameOnParent() {
      var name = get(this, 'name');
      var container = this.get('parentComponent');
      var defaultState = get(this, 'default');
      if (!get(this, 'baselayer')) {
        var layerGroups = get(container, '_layerGroups');
        if (isEmpty(layerGroups)) {
          layerGroups = Ember.A();
          set(container, '_layerGroups', layerGroups);
        }
        layerGroups.addObject({
          name: name,
          layer: get(this, '_layer'),
          default: defaultState
        });
      } else {
        var baseLayers = get(container, '_baseLayers');
        if (isEmpty(baseLayers)) {
          baseLayers = Ember.A();
          set(container, '_baseLayers', baseLayers);
        }
        baseLayers.addObject({
          name: name,
          layer: get(this, '_layer._layer'),
          default: defaultState
        });
      }
    },
    didInsertParent: function didInsertParent() {
      if (isNone(get(this, '_layer'))) {
        if (!get(this, 'baselayer')) {
          set(this, 'blockLayer', true);
          this._layer = this.createLayer();
          if (get(this, 'parentComponent')) {
            if (!isNone(get(this, 'parentComponent')._layer)) {
              this.addToContainer();
            }
          }
        } else {
          this._layer = {
            _layer: null,
            addLayer: function addLayer(layer) {
              this._layer = layer;
            },
            removeLayer: function removeLayer() {
              this._layer = null;
            }
          };
        }
        this.didCreateLayer();
      }
    },
    didCreateLayer: function didCreateLayer() {
      this._super.apply(this, arguments);
      var invokeChildDidInsertHooks = get(this, 'invokeChildDidInsertHooks');
      /* create the children of this layer group early, so they exist when we run registerNameOnParent */
      invokeChildDidInsertHooks.call(this);
    },
    invokeChildDidInsertHooks: function invokeChildDidInsertHooks() {
      var insertedChildren = get(this, 'insertedChildren');
      if (!insertedChildren) {
        this._super.apply(this, arguments);
        this.registerNameOnParent();
        set(this, 'insertedChildren', true);
      }
    }
  });
});