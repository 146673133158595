define('ember-leaflet-contextmenu/components/leaflet-contextmenu-item', ['exports', 'ember-leaflet/components/base-layer', 'ember-leaflet-contextmenu/components/leaflet-contextmenu'], function (exports, _baseLayer, _leafletContextmenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = Ember.assert,
      assign = Ember.assign,
      observer = Ember.observer;
  exports.default = _baseLayer.default.extend({
    addToContainer: function addToContainer() {
      var parentComponent = this.get('parentComponent');
      var options = assign({ callback: this.get('action') }, this.get('options'));

      assert('Must be wrapped in {{leaflet-contextmenu}}', parentComponent instanceof _leafletContextmenu.default);

      this._element = this._layer = parentComponent._map.contextmenu.addItem(options);
    },
    createLayer: function createLayer() {
      // nothing to do here
    },


    disabledChanged: observer('disabled', function () {
      var parentComponent = this.get('parentComponent');
      var disabled = this.get('disabled');
      parentComponent._map.contextmenu.setDisabled(this._element, disabled);
    }),

    leafletOptions: ['text', 'icon', 'retinaIcon', 'iconCls', 'iconCls', 'disabled'],

    removeFromContainer: function removeFromContainer() {
      this.get('parentComponent')._map.contextmenu.removeItem(this._element);
    }
  });
});