define('fixtable-ember/components/fixtable-footer', ['exports', 'ember', 'fixtable-ember/templates/components/fixtable-footer'], function (exports, _ember, _fixtableEmberTemplatesComponentsFixtableFooter) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _fixtableEmberTemplatesComponentsFixtableFooter['default'],
    currentPage: null,
    totalPages: null,
    pageSize: null,
    pageSizeOptions: null,

    actions: {
      goToPreviousPage: function goToPreviousPage() {
        this.sendAction("goToPreviousPage");
      },
      goToNextPage: function goToNextPage() {
        this.sendAction("goToNextPage");
      }
    }
  });
});