define('fixtable-ember/components/fixtable-grid', ['exports', 'ember-data', 'ember', 'lodash', 'fixtable-ember/templates/components/fixtable-grid'], function (exports, _emberData, _ember, _lodash, _fixtableEmberTemplatesComponentsFixtableGrid) {

  var checkboxColumnWidth = 40; // in pixels
  var defaultPossiblePageSizes = [25, 50, 100, 250, 500];
  var defaultPage = 1;
  var defaultPageSize = 25;
  var toComparableString = function toComparableString(x) {
    return x === null || typeof x === 'undefined' ? '' : x + '';
  };
  var getCellData = function getCellData(row, key) {
    return row.get ? row.get(key) : row[key];
  };

  exports['default'] = _ember['default'].Component.extend({
    layout: _fixtableEmberTemplatesComponentsFixtableGrid['default'],
    fixtable: null,
    columnsByKey: null,
    debugMode: false,

    // paging
    clientPaging: false,
    serverPaging: false,
    showPaging: _ember['default'].computed.or('clientPaging', 'serverPaging'),
    totalRowsOnServer: 0, // only used for server paging
    showPaginationFooter: _ember['default'].computed.and('showPaging', 'visibleContent.length'),

    // filters
    filters: null,
    filterToApply: null,
    filterDebounce: 500,
    realtimeFiltering: true,
    filtersAreActive: false,
    filtersAreDirty: false,

    // sorting
    sortBy: null,
    sortAscending: true,

    // row selection
    rowSelection: false,
    selectedRowMap: null, // maps row indices to selected state
    suppressSelectToggle: false,

    // custom components
    emptyStateComponent: 'fixtable-empty-state',
    emptyStateComponentValues: { nullMessage: 'No data available' },
    footerComponent: 'fixtable-footer',

    // backwards-compatibility for old nullMessage option
    // TODO: remove this in fixtable-ember v4.0.0
    nullMessageChanged: _ember['default'].observer('nullMessage', function () {
      _ember['default'].Logger.warn('Deprecation warning: use emptyStateComponentValues instead of nullMessage. Support will be dropped in fixtable-ember v4.x. See https://github.com/MyPureCloud/fixtable-ember#empty-state-component for more info.');
      this.set('emptyStateComponentValues.nullMessage', this.get('nullMessage'));
    }),

    externalFilters: null,

    afterCurrentPageChanged: _ember['default'].observer('currentPage', function fixtableGrid$afterCurrentPageChanged() {
      var currentPage = this.get('currentPage'),
          totalPages = this.get('totalPages');

      if (currentPage < 1) {
        this.set('currentPage', 1);
      } else if (currentPage > totalPages) {
        this.set('currentPage', totalPages);
      }
      //Ember.run.once(this, this.notifyReloadContent);
      _ember['default'].run.debounce(this, this.notifyReloadContent, 300, false);
    }),

    afterPageSizeChanged: _ember['default'].observer('pageSize', function fixtableGrid$afterPageSizeChanged() {
      _ember['default'].run.once(this, this.notifyReloadContent);
      this.set('currentPage', defaultPage);
    }),

    notifyReloadContent: function notifyReloadContent() {
      var handler = this.get('onReloadContent');
      if (typeof handler === 'function') {
        var sortBy = this.get('sortBy');
        var sortInfo = sortBy ? { key: sortBy, ascending: this.get('sortAscending') } : null;

        handler(this.get('currentPage'), this.get('pageSize'), this.get('filterToApply') || {}, sortInfo, this.get('externalFilters'));
      }

      // if we need to reload content, that also means the selection will be reset
      this.notifyRowSelectionChanged([]);
    },

    pageSizeOptions: _ember['default'].computed('totalRows', function fixtableGrid$pageSizeOptions() {
      // limit the page size options based on content size
      var i = 0;
      var possiblePageSizes = this.get('possiblePageSizes');
      while (i < possiblePageSizes.length && possiblePageSizes[i] < this.get('totalRows')) {
        i++;
      }
      return possiblePageSizes.slice(0, i + 1);
    }),

    showManualFilterButtons: _ember['default'].computed('realtimeFiltering', 'filters', function fixtableGrid$showManualFilterButtons() {
      return !this.get('realtimeFiltering') && Object.keys(this.get('filters')).length;
    }),

    onFilterChanged: function onFilterChanged() /*filters, columnKey*/{
      this.set('filtersAreDirty', true);
      if (this.get('realtimeFiltering')) {
        _ember['default'].run.debounce(this, this.applyFilter, this.get('filterDebounce'));
      }
    },

    applyFilter: function applyFilter() {
      // update the filterToApply property to trigger a change in sortedFilteredContent
      var filters = this.get('filters');
      this.set('filterToApply', JSON.parse(JSON.stringify(filters)));
      this.set('currentPage', 1);
      this.set('filtersAreDirty', false);

      var filtersAreActive = Object.keys(filters).some(function (key) {
        return !!filters[key];
      });
      this.set('filtersAreActive', filtersAreActive);

      _ember['default'].run.once(this, this.notifyReloadContent);
    },

    clearFilter: function clearFilter() {
      // clear all the bound filter values and re-apply
      var filters = this.get('filters');
      Object.keys(filters).forEach(function (key) {
        return filters.set(key, '');
      });
      this.applyFilter();
    },

    sortByColumn: function sortByColumn(columnKey) {
      if (this.get('sortBy') === columnKey) {
        this.set('sortAscending', !this.get('sortAscending'));
      } else {
        this.set('sortBy', columnKey);
        this.set('sortAscending', true);
      }
    },

    afterSortChanged: _ember['default'].observer('sortBy', 'sortAscending', function fixtableGrid$afterSortChanged() {
      this.set('currentPage', defaultPage);
      _ember['default'].run.once(this, this.notifyReloadContent);
    }),

    sortedContent: _ember['default'].computed('content.[]', 'serverPaging', 'sortBy', 'sortAscending', function fixtableGrid$sortedContent() {
      var content = this.get('content') || [];
      var sortBy = this.get('sortBy');

      // don't sort on the client if server pagination is on, or if no sort specified
      if (this.get('serverPaging') || !sortBy) {
        return content;
      }

      var sortedContent = content.slice(); // don't mutate the original collection
      sortedContent.sort(this.getSortFunction(sortBy));

      return sortedContent;
    }),

    getSortFunction: function getSortFunction(sortBy) {
      var _this = this;

      var customSort = this.get('columnsByKey')[sortBy] && this.get('columnsByKey')[sortBy].sortFunction;

      if (typeof customSort === 'function') {
        // use the custom sort function
        return function (leftRow, rightRow) {
          var leftVal = getCellData(leftRow, sortBy);
          var rightVal = getCellData(rightRow, sortBy);
          return _this.get('sortAscending') ? customSort(leftVal, rightVal) : customSort(rightVal, leftVal);
        };
      } else {
        // use lexicographical sorting if no custom sort function is specified
        return function (leftRow, rightRow) {
          var leftVal = toComparableString(getCellData(leftRow, sortBy));
          var rightVal = toComparableString(getCellData(rightRow, sortBy));
          return _this.get('sortAscending') ? leftVal.localeCompare(rightVal) : rightVal.localeCompare(leftVal);
        };
      }
    },

    sortedFilteredContent: _ember['default'].computed('sortedContent', 'filterToApply', 'serverPaging', function fixtableGrid$sortedFilteredContent() {
      var sortedContent = this.get('sortedContent') || [];

      if (this.get('serverPaging')) {
        return sortedContent; // don't filter on the client if server pagination is on
      }

      return this.getFilteredContent(sortedContent);
    }),

    getFilteredContent: function getFilteredContent(content) {
      // client filtering
      var columnsByKey = this.get('columnsByKey');
      var filters = this.get('filterToApply') || {};
      var columnKeys = Object.keys(filters);

      return content.filter(function (row) {
        return columnKeys.every(function (columnKey) {
          var filterDef = columnsByKey[columnKey].filter;
          var filterFunction = filterDef.filterFunction;
          var filterValue = filters[columnKey];

          // custom filter
          if (typeof filterFunction === 'function') {
            return filterFunction(row, filterValue);
          }

          // no filter
          if (!filters[columnKey]) {
            return true;
          }

          // normalize the cell and filter values
          var cellData = (getCellData(row, columnKey) || '').toLowerCase();
          filterValue = filterValue.toLowerCase();

          // select-type filter
          if (columnsByKey[columnKey].filter.type === 'select') {
            return cellData === filterValue;
          }

          // search-type filter
          return cellData.includes(filterValue);
        });
      });
    },

    visibleContent: _ember['default'].computed('sortedFilteredContent', 'currentPage', 'pageSize', 'clientPaging', function fixtableGrid$visibleContent() {
      var sortedFilteredContent = this.get('sortedFilteredContent');

      // ensure sortedFilteredContent is a supported type
      var allowedTypes = [Array, _ember['default'].ArrayProxy, _emberData['default'].ManyArray];
      var isAllowedType = allowedTypes.some(function (type) {
        return sortedFilteredContent instanceof type;
      });
      if (!isAllowedType) {
        _ember['default'].Logger.warn('Content supplied to Fixtable is not a supported type');
        return [];
      }

      if (this.get('clientPaging')) {
        var currentPage = this.get('currentPage');
        var pageSize = this.get('pageSize');
        sortedFilteredContent = sortedFilteredContent.slice((currentPage - 1) * pageSize, currentPage * pageSize);
      }

      return sortedFilteredContent.map(function (rowObject) {
        return {
          object: rowObject,
          hover: false,
          expanded: false
        };
      });
    }),

    totalColumns: _ember['default'].computed('columns.[]', 'rowSelection', function fixtableGrid$totalColumns() {
      return this.get('columns').length + (this.get('rowSelection') ? 1 : 0);
    }),

    totalRows: _ember['default'].computed('sortedFilteredContent.[]', 'serverPaging', 'totalRowsOnServer', function fixtableGrid$totalRows() {
      if (this.get('serverPaging')) {
        return this.get('totalRowsOnServer');
      }

      var sortedFilteredContent = this.get('sortedFilteredContent') || [];
      return sortedFilteredContent.length;
    }),

    totalPages: _ember['default'].computed('totalRows', 'pageSize', function fixtableGrid$totalPages() {
      return Math.ceil(this.get('totalRows') / this.get('pageSize'));
    }),

    resetSelection: _ember['default'].observer('visibleContent.[]', function fixtableGrid$resetSelection() {
      if (!this.get('rowSelection')) {
        return;
      }

      var selectedRowMap = _ember['default'].Object.create();
      this.set('selectedRowMap', selectedRowMap);
      this.get('visibleContent').forEach(function (row, rowIndex) {
        selectedRowMap.set(rowIndex.toString(), false);
      });

      var selectionKeys = Object.keys(selectedRowMap);
      var self = this;
      selectionKeys.forEach(function (key) {
        if (!selectedRowMap.hasObserverFor(key)) {
          selectedRowMap.addObserver(key, self, 'onRowSelectedOrDeselected');
        }
      });

      this.setSelectAllToggleChecked(false);
      this.setSelectAllToggleIndeterminate(false);
    }),

    setSelectAllToggleChecked: function setSelectAllToggleChecked(value) {
      this.set('suppressSelectToggle', true);
      this.set('selectAllToggle', value);
      this.set('suppressSelectToggle', false);
    },

    setSelectAllToggleIndeterminate: function setSelectAllToggleIndeterminate(indeterminate) {
      if (!this.get('rowSelection')) {
        return;
      }

      var selector = '.fixtable-column-headers th .fixtable-checkbox input[type=checkbox]';
      var element = this.get('element');
      if (element) {
        var checkbox = element.querySelector(selector);
        checkbox.indeterminate = indeterminate;
      }
    },

    onRowSelectedOrDeselected: function onRowSelectedOrDeselected(selectedRowMap /*, rowIndex*/) {
      var _this2 = this;

      // selectedRowMap maps row indices in visibleContent to their selection status
      if (!this.get('rowSelection')) {
        return;
      }

      var selectedDataRows = Object.keys(selectedRowMap).filter(function (index) {
        return selectedRowMap[index];
      }).map(function (index) {
        return _this2.get('visibleContent')[index];
      });

      var numSelected = selectedDataRows.length;
      var allRowsAreSelected = numSelected === this.get('visibleContent').length;
      var someRowsAreSelected = numSelected > 0;

      if (allRowsAreSelected) {
        this.setSelectAllToggleChecked(true);
        this.setSelectAllToggleIndeterminate(false);
      } else if (someRowsAreSelected) {
        this.setSelectAllToggleChecked(false);
        this.setSelectAllToggleIndeterminate(true);
      } else {
        this.setSelectAllToggleChecked(false);
        this.setSelectAllToggleIndeterminate(false);
      }

      this.notifyRowSelectionChanged(selectedDataRows);
    },

    notifyRowSelectionChanged: function notifyRowSelectionChanged(selectedDataRows) {
      this.set('selectedDataRows', selectedDataRows.map(function (row) {
        return row.object;
      }));
      var handler = this.get('onSelectionChanged');
      if (typeof handler === 'function') {
        handler(this.get('selectedDataRows'));
      }
    },

    toggleSelectAll: _ember['default'].observer('selectAllToggle', function fixtableGrid$toggleSelectAll() {
      if (this.get('suppressSelectToggle') || !this.get('rowSelection')) {
        return; // quit if we're programmatically setting the property, not responding to user input
      }

      var selectedRowMap = this.get('selectedRowMap');
      var selectedRowKeys = Object.keys(selectedRowMap);

      var numSelected = selectedRowKeys.filter(function (rowIndex) {
        return selectedRowMap[rowIndex];
      }).length;
      var allRowsAreSelected = numSelected === this.get('visibleContent').length;

      // If all rows already selected, deselect all. Otherwise, select all.
      selectedRowKeys.forEach(function (rowIndex) {
        return selectedRowMap.set(rowIndex, !allRowsAreSelected);
      });

      this.setSelectAllToggleIndeterminate(false); // if the user clicked, it's not indeterminate
    }),

    safeSetCurrentPage: function safeSetCurrentPage(newPage) {
      var validPageNum = Math.min(Math.max(1, newPage), this.get('totalPages'));
      this.set('currentPage', validPageNum);
    },

    actions: {
      goToPreviousPage: function goToPreviousPage() {
        var currentPage = parseInt(this.get('currentPage'));
        this.safeSetCurrentPage(currentPage - 1);
      },
      goToNextPage: function goToNextPage() {
        var currentPage = parseInt(this.get('currentPage'));
        this.safeSetCurrentPage(currentPage + 1);
      },

      applyManualFilter: function applyManualFilter() {
        this.applyFilter();
      },
      clearManualFilter: function clearManualFilter() {
        this.clearFilter();
      },

      sortColumn: function sortColumn(columnKey) {
        this.sortByColumn(columnKey);
      },

      onRowClick: function onRowClick(row) {
        var handler = this.get('onRowClick');
        if (typeof handler === 'function') {
          handler(row);
        }
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      this.indexColumns();
      this.updateFilterObservers();
      this.resetSelection();
      this.setDefaults();
    },

    indexColumns: function indexColumns() {
      var columnsByKey = {};
      this.get('columns').forEach(function (column) {
        columnsByKey[column.key] = column;
      });
      this.set('columnsByKey', columnsByKey);
    },

    updateFilterObservers: function updateFilterObservers() {
      var filters = _ember['default'].Object.create();
      this.set('filters', filters);

      this.get('columns').forEach(function (colDef) {
        if (colDef.filter && typeof filters[colDef.key] === 'undefined') {
          filters[colDef.key] = "";
        }
      });

      var filterKeys = Object.keys(filters);
      var self = this;
      filterKeys.forEach(function (key) {
        if (!filters.hasObserverFor(key)) {
          filters.addObserver(key, self, 'onFilterChanged');
        }
      });
    },

    setDefaults: function setDefaults() {
      if (!this.get('pageSize')) {
        this.set('pageSize', defaultPageSize);
      }
      if (!this.get('possiblePageSizes')) {
        this.set('possiblePageSizes', defaultPossiblePageSizes);
      }
      if (!this.get('currentPage')) {
        this.set('currentPage', defaultPage);
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].run.later(this, this.initializeFixtable, 0);
    },

    initializeFixtable: function initializeFixtable() {
      var fixtableElement = this.$('.fixtable') ? this.$('.fixtable')[0] : undefined; //codes around a bug in ember test where jquery can't find the fixtable element initially
      if (!fixtableElement) {
        return;
      }
      // initialize the Fixtable script
      var fixtable = new Fixtable(this.$('.fixtable')[0], this.get('debugMode'));

      // account for the row selection checkbox column, if present
      var indexOffset = 1;
      if (this.get('rowSelection')) {
        indexOffset++;
        fixtable.setColumnWidth(1, checkboxColumnWidth);
      }

      // set fixtable column widths
      this.get('columns').forEach(function (col, index) {
        if (col.width) {
          fixtable.setColumnWidth(index + indexOffset, col.width);
        }
      });

      fixtable.setDimensions();
      this.set('fixtable', fixtable);
      this.notifyReloadContent();
    },

    didRender: function didRender() {
      // force the Fixtable to resize itself when rendered
      this._super.apply(this, arguments);
      var fixtable = this.get('fixtable');
      if (fixtable) {
        fixtable.setDimensions();
      }
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      // trigger content reload when externalFilters change
      var oldExternalFilters = this.get('_previousExternalFilters');
      var newExternalFilters = _lodash['default'].cloneDeep(this.get('externalFilters'));
      if (oldExternalFilters && !_lodash['default'].isEqual(oldExternalFilters, newExternalFilters)) {
        this.set('currentPage', 1);
        _ember['default'].run.debounce(this, this.notifyReloadContent, 300, false);
      }
      this.set('_previousExternalFilters', newExternalFilters);
    }
  });
});
/* global Fixtable */